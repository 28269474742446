<template>
  <div id="page">
    <div id="content-area">
      <h1>Tak for din besvarelse!</h1>
      <div class="section-image">
        <img :src="illustrationUrl" alt="" class="">
      </div>
      <p v-if="type === 'demo'">Du kan se hvordan resultatet og anbefalinger bliver vist i én samlet rapport.</p>
      <base-ui-button v-if="type === 'demo'" @clicked="$router.push('/rapport/personlig')" primary>Klik her</base-ui-button>
      <p v-if="type !== 'demo'">Spørgeskemaundersøgelsen er nu afsluttet - og dit svar er blevet gemt. Hvis du er blevet nysgerrig på, hvordan stress kan forebygges gennem stærke  arbejdsfællesskaber, kan du læse mere på <a href="https://coreculture.dk" target="_blank">coreculture.dk</a>.</p>
    </div>
  </div>
</template>

<script>
import illustrationUrl from '../assets/imgs/illustration2.svg'
import BaseUiButton from '../components/shared/BaseUiButton.vue'

export default {
  components: {
    BaseUiButton
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      illustrationUrl: illustrationUrl
    }
  },
  mounted () {
    this.$emit('update-background', 'rgb(253,252,248)')
  }
}
</script>

<style lang="stylus" scoped>
.section-image
  padding-top 2rem
  img
    width 50%
</style>
